import React, { Component } from 'react';
import NewLayout from '../layout/newLayout'
import Title from '../components/title';
import styles from '../styles/scss/pages/electricalCleaner.module.scss';
import Text from '../components/text';
import SEO from "../components/seo";
import YouTube from 'react-youtube';
import CtaBanner from "../components/ctaBanner";
import Button from '../components/button';
import Imgix from 'react-imgix';
export default class ElectricalCleaner extends Component {

  constructor(props) {
    super(props)
    this.state = { use1: false, use2: false, use3: false };
    this.toggleUse1 = this.toggleUse1.bind(this);
    this.toggleUse2 = this.toggleUse2.bind(this);
    this.toggleUse3 = this.toggleUse3.bind(this);
    this.getProductContainerStyle = this.getProductContainerStyle.bind(this);
  }

  toggleUse1 = () => {
    const { use1 } = this.state;
    this.setState({ use1: !use1, use2: false, use3: false })
  }
  toggleUse2 = () => {
    const { use2 } = this.state;
    this.setState({ use2: !use2, use1: false, use3: false })
  }
  toggleUse3 = () => {
    const { use3 } = this.state;
    this.setState({ use3: !use3, use1: false, use2: false })
  }

  getProductContainerStyle = (toggle) => {
    const areAllClose = !this.state.use1 && !this.state.use2 && !this.state.use3;
    if (toggle && !areAllClose) {
      return {
        background: 'white',
        borderTop: '#ffed00 solid',
        borderLeft: '#ffed00 solid',
        borderRight: '#ffed00 solid',
        borderBottom: '',
        color: '#1c3687 !important'
      }
    }
    if (!toggle && areAllClose) {
      return {
        background: 'white',
        borderTop: '',
        borderLeft: '',
        borderRight: '',
        borderBottom: ''
      }
    }
    return {
      background: '#1c3687',
      borderTop: '',
      borderLeft: '',
      borderRight: '',
      borderBottom: '#ffed00 solid'
    }
  }

  render() {
    const pageData = this.props.pageContext.page;

    return (
      <NewLayout>
        <SEO page={pageData} />
        <Imgix sizes='100vw' alt='showcase' src="/img/electrical-cleaner/hero.png" className={styles.hero} htmlAttributes={{ alt: 'showcase' }} />
        <div className={styles.container}>
          <Text className={styles.subContainer}>
            <Title className={styles.videoTitle} value='Electronics Maintenance Bundle' />
            <p className={styles.grayText}>
              This bundle has everything to handle your electronics and electromechanical maintenance needs. Great for use on drones, 3D printers, robotics, printed circuit board flux and
              general electronic component maintenance.<br />
              <br />
              <strong> WD-40<span className={styles.copyright}>®</span> Specialist<span className={styles.copyright}>®</span> Electronics and Electrical Parts Cleaner </strong> will help you remove dirt, dust and oils from your sensitive
              electronics and connections. For your fast-moving motors, stuck switches, controls, and other long-lasting lubricant
              needs, use <strong>WD-40<span className={styles.copyright}>®</span> Specialist<span className={styles.copyright}>®</span> Spray & Stay Gel Lubricant</strong>. It won't run or drip. And for those more precise needs, use
              <strong> 3-IN-ONE<span className={styles.copyright}>®</span> Multi-Purpose PTFE Lubricant</strong> designed to reduce friction and wear in your electromechanical devices and
              the tools you use to keep them running.<br />
              <br />
              *Always follow manufacturer's recommended maintenance for cleaning and lubricating your electronics.
            </p>
          </Text>
          <div className={styles.videoContainer}>
            <Title className={styles.videoTitle} value='SEE IT IN ACTION' />
            <div className={styles.iframeContainer}>
              <YouTube
                videoId={'OaCv0P6vH2w'}
                opts={{
                  height: '390',
                  width: 'auto',
                }}
                className={styles.videoOpts}
                onReady={this._onReady}
              />
            </div>
          </div>
        </div>
        <div id="bottomContainer" className={styles.bottomContainer}>
          <Title className={styles.bottomBoxTitle} value='Electrical Maintenance Products' />
          <div className={styles.bottomBox}>
            <div className={styles.productDisplay} style={this.getProductContainerStyle(this.state.use1)}>
              <div className={styles.productContainer}>
                <div className={styles.productInfoMobile}>
                  <div className={styles.textwrapper}>
                    <Title className={styles.bottomProductTitle} value='WD-40® Specialist® Electronics and Electrical Parts Cleaner' />
                  </div>
                </div>
                <Imgix sizes='100vw' className={styles.productImage} src='/img/electrical-cleaner/new01.png' htmlAttributes={{ alt: '' }} />
                <div className={styles.productInfoMobile}>
                  <div className={styles.buttonProduct}>
                    {!this.state.use1 && <Button red url={'/electrical-cleaner/#bottomContainer'} onClick={this.toggleUse1} text='Learn More' />}
                  </div>
                </div>
                <div className={styles.productInfo}>
                  <div className={styles.textwrapper}>
                    <Title className={styles.bottomProductTitle} value='WD-40® Specialist® Electronics and Electrical Parts Cleaner' />
                  </div>
                  <div className={styles.buttonProduct}>
                    {!this.state.use1 && <Button red url={'/electrical-cleaner/#bottomContainer'} onClick={this.toggleUse1} text='Learn More' />}
                  </div>
                </div>
              </div>

              {this.state.use1 && (
                <div value='use1' className={styles.productInfoDetailMobile}  >
                  <div><Imgix sizes='100vw' className={styles.productImageUse} src='/img/electrical-cleaner/01use.png' htmlAttributes={{ alt: '' }} /></div>
                  <div className={styles.productInfoContainer}>
                    <Title value='WD-40® Specialist® Electronics and Electrical Parts Cleaner' />
                    <Text>
                      <p className={styles.grayText}>
                        Quickly and easily remove dust, dirt, and oil from your sensitive electronic components and connections. WD-40<span className={styles.copyright}>®</span> Specialist<span className={styles.copyright}>®</span> Electronics and Electrical Parts Cleaner works fast and dries quick with no residue, removing more dust and dirt than leading competitors. Safe to use, NSF Category 2 (#146857) and 50 state VOC compliant, it is perfect for use on charging and USB cables, battery connections, small motors, and printed circuit boards. Safe on plastic, rubber and metal.
                      </p>
                    </Text >
                    <a className={styles.grayTextLink} target="_blank" rel="noopener noreferrer" href="https://files.wd40.com/pdf/WD-40+Specialist+Electronics+Cleaner+SDS.pdf">SDS Information</a>
                    <a className={styles.grayTextLink} target="_blank" rel="noopener noreferrer" href="https://files.wd40.com/pdf/WD-40+Specialist+Electronics+Cleaner+TDS+Sheet+3-3-20.pdf">Tech Information</a>
                  </div>
                </div>
              )}
            </div>

            <div className={styles.productDisplay} style={this.getProductContainerStyle(this.state.use2)}>
              <div className={styles.productContainer}>
                <div className={styles.productInfoMobile}>
                  <div className={styles.textwrapper}>
                    <Title className={styles.bottomProductTitle} value='WD-40® Specialist® SPRAY & STAY GEL LUBRICANT' />
                  </div>
                </div>
                <Imgix className={styles.productImage} src='/img/electrical-cleaner/new02.png' htmlAttributes={{ alt: '' }} />
                <div className={styles.productInfoMobile}>
                  <div className={styles.buttonProduct}>
                    {!this.state.use2 && <Button red url={'/electrical-cleaner/#bottomContainer'} onClick={this.toggleUse2} text='Learn More' />}
                  </div>
                </div>
                <div className={styles.productInfo}>
                  <div className={styles.textwrapper}>
                    <Title className={styles.bottomProductTitle} value='WD-40® Specialist® SPRAY & STAY GEL LUBRICANT' />
                  </div>
                  <div className={styles.buttonProduct}>
                    {!this.state.use2 && <Button red url={'/electrical-cleaner/#bottomContainer'} onClick={this.toggleUse2} text='Learn More' />}
                  </div>
                </div>
              </div>

              {this.state.use2 && (
                <div value='use2' className={styles.productInfoDetailMobile}>
                  <Imgix sizes='100vw'  className={styles.productImageUse} src='/img/electrical-cleaner/02use.jpg' htmlAttributes={{ alt: '' }} />
                  <div className={styles.productInfoContainer}>
                    <Title value='WD-40® Specialist® SPRAY & STAY GEL LUBRICANT' />
                    <Text>
                      <p className={styles.grayText}>
                        WD-40<span className={styles.copyright}>®</span> Specialist<span className={styles.copyright}>®</span> Spray & Stay Gel Lubricant No-Drip Formula offers 12 times longer-lasting lubrication compared to WD-40 Multi-Use Product. This unique spray gel lubricant stays where you spray, making it ideal for use on vertical surfaces and moving electronic and electromechanical parts. This thick formula also prevents rust for up to one year. Great for gears, bearings, threads, rotors, and more without making a mess. Effective in-use temperatures ranging from -100⁰ F to 500⁰ F. 50-state VOC compliant and safe on multiple surfaces including metals, plastic and rubber.
                      </p>
                    </Text >
                    <a className={styles.grayTextLink} target="_blank" rel="noopener noreferrer" href="https://files.wd40.com/pdf/sds/specialist/wd-40-specialist-spray-and-stay-gel-lubricant-aerosol-us-ghs-sds.pdf">SDS Information</a>
                    <a className={styles.grayTextLink} target="_blank" rel="noopener noreferrer" href="https://www.datocms-assets.com/10845/1562018183-spray-stay-gel-lubricant.pdf">Tech Information</a>
                  </div>
                </div>
              )}
            </div>

            <div className={styles.productDisplay} style={this.getProductContainerStyle(this.state.use3)}>
              <div className={styles.productContainer}>
                <div className={styles.productInfoMobile}>
                  <div className={styles.textwrapper}>
                    <Title className={styles.bottomProductTitle} value='3-IN-ONE® Multi-Purpose PTFE Lubricant' />
                  </div>
                </div>
                <Imgix className={styles.product3in1} src='/img/electrical-cleaner/03.png' htmlAttributes={{ alt: '' }} />
                <div className={styles.productInfoMobile}>
                  <div className={styles.buttonProduct}>
                    {!this.state.use3 && <Button red url={'/electrical-cleaner/#bottomContainer'} onClick={this.toggleUse3} text='Learn More' />}
                  </div>
                </div>
                <div className={styles.productInfo}>
                  <div className={styles.textwrapper}>
                    <Title className={styles.bottomProductTitle} value='3-IN-ONE® Multi-Purpose PTFE Lubricant' />
                  </div>
                  <div className={styles.buttonProduct}>
                    {!this.state.use3 && <Button red url={'/electrical-cleaner/#bottomContainer'} onClick={this.toggleUse3} text='Learn More' />}
                  </div>
                </div>
              </div>
              {this.state.use3 && (
                <div value='use3' className={styles.productInfoDetailMobile}>
                  <Imgix sizes='100vw' className={styles.productImageUse} src='/img/electrical-cleaner/03use.jpg' width={100} htmlAttributes={{ alt: '' }} />
                  <div class={styles.productInfoContainer}>
                    <Title value='3-IN-ONE® Multi-Purpose PTFE Lubricant' />
                    <Text>
                      <p className={styles.grayText}>
                        Perform under pressure with this PTFE lubricant designed for all your heavy-load tools and electromechanical equipment. 3-IN-ONE<span className={styles.copyright}>®</span> Multi-Purpose PTFE Lubricant reduces friction and wear on your electronic moving parts and can maintain its lubricating properties at extreme pressures and high temperatures.This long-lasting PTFE lubricant is safe for both metals and plastics (except polycarbonate and polystyrene), and comes with a built-in Marksman Twist Spout, making it easy and quick to get a precise application without all the hassle or mess. Effective from -50⁰ F to 500⁰ F and will help extend the life of your equipment.
                      </p>
                    </Text>
                    <a className={styles.grayTextLink} target="_blank" rel="noopener noreferrer" href="https://files.wd40.com/pdf/sds/3inone/3-in-one-multi-purpose-ptfe-lubricant-drip-oil-us-ghs-sds.pdf">SDS Information</a>
                    <a className={styles.grayTextLink} target="_blank" rel="noopener noreferrer" href="https://files.wd40.com/pdf/3N1+PTFE+TDS+5-9-17+(1).pdf">Tech Information</a>
                  </div>
                </div>
              )}
            </div>

          </div>
          <div>
            <div>
              {this.state.use1 && (
                <div value='use1' className={styles.productInfoDetail}>
                  <div><Imgix className={styles.productImageUse} src='/img/electrical-cleaner/01use.png' htmlAttributes={{ alt: '' }} /></div>
                  <div className={styles.productInfoContainer}>
                    <Title value='WD-40® Specialist® Electronics and Electrical Parts Cleaner' />
                    <Text>
                      <p className={styles.grayText}>
                        Quickly and easily remove dust, dirt, and oil from your sensitive electronic components and connections. WD-40<span className={styles.copyright}>®</span> Specialist<span className={styles.copyright}>®</span> Electronics and Electrical Parts Cleaner works fast and dries quick with no residue, removing more dust and dirt than leading competitors. Safe to use, NSF Category 2 (#146857) and 50 state VOC compliant, it is perfect for use on charging and USB cables, battery connections, small motors, and printed circuit boards. Safe on plastic, rubber and metal.
                      </p>
                    </Text >
                    <a className={styles.grayTextLink} target="_blank" rel="noopener noreferrer" href="https://files.wd40.com/pdf/WD-40+Specialist+Electronics+Cleaner+SDS.pdf">SDS Information</a> <br />
                    <a className={styles.grayTextLink} target="_blank" rel="noopener noreferrer" href="https://files.wd40.com/pdf/WD-40+Specialist+Electronics+Cleaner+TDS+Sheet+3-3-20.pdf">Tech Information</a>
                  </div>
                </div>
              )}
              {this.state.use2 && (
                <div value='use2' className={styles.productInfoDetail}>
                  <Imgix sizes='100vw' className={styles.productImageUse} src='/img/electrical-cleaner/02use.jpg' htmlAttributes={{ alt: '' }} />
                  <div className={styles.productInfoContainer}>
                    <Title value='WD-40® Specialist® SPRAY & STAY GEL LUBRICANT' />
                    <Text>
                      <p className={styles.grayText}>
                        WD-40<span className={styles.copyright}>®</span> Specialist<span className={styles.copyright}>®</span> Spray & Stay Gel Lubricant No-Drip Formula offers 12 times longer-lasting lubrication compared to WD-40<span className={styles.copyright}>®</span> Multi-Use Product. This unique spray gel lubricant stays where you spray, making it ideal for use on vertical surfaces and moving electronic and electromechanical parts. This thick formula also prevents rust for up to one year. Great for gears, bearings, threads, rotors, and more without making a mess. Effective in-use temperatures ranging from -100⁰ F to 500⁰ F. 50-state VOC compliant and safe on multiple surfaces including metals, plastic and rubber.
                      </p>
                    </Text >
                    <a className={styles.grayTextLink} target="_blank" rel="noopener noreferrer" href="https://files.wd40.com/pdf/sds/specialist/wd-40-specialist-spray-and-stay-gel-lubricant-aerosol-us-ghs-sds.pdf">SDS Information</a> <br />
                    <a className={styles.grayTextLink} target="_blank" rel="noopener noreferrer" href="https://www.datocms-assets.com/10845/1562018183-spray-stay-gel-lubricant.pdf">Tech Information</a>
                  </div>
                </div>
              )}
              {this.state.use3 && (
                <div value='use3' className={styles.productInfoDetail}>
                  <Imgix sizes='100vw' className={styles.productImageUse} src='/img/electrical-cleaner/03use.jpg' width={100} htmlAttributes={{ alt: '' }} />
                  <div class={styles.productInfoContainer}>
                    <Title value='3-IN-ONE® Multi-Purpose PTFE Lubricant' />
                    <Text>
                      <p className={styles.grayText}>
                        Perform under pressure with this PTFE lubricant designed for all your heavy-load tools and electromechanical equipment. 3-IN-ONE<span className={styles.copyright}>®</span> Multi-Purpose PTFE Lubricant reduces friction and wear on your electronic moving parts and can maintain its lubricating properties at extreme pressures and high temperatures.This long-lasting PTFE lubricant is safe for both metals and plastics (except polycarbonate and polystyrene), and comes with a built-in Marksman Twist Spout, making it easy and quick to get a precise application without all the hassle or mess. Effective from -50⁰ F to 500⁰ F and will help extend the life of your equipment.
                      </p>
                    </Text>
                    <a className={styles.grayTextLink} target="_blank" rel="noopener noreferrer" href="https://files.wd40.com/pdf/sds/3inone/3-in-one-multi-purpose-ptfe-lubricant-drip-oil-us-ghs-sds.pdf">SDS Information</a> <br />
                    <a className={styles.grayTextLink} target="_blank" rel="noopener noreferrer" href="https://files.wd40.com/pdf/3N1+PTFE+TDS+5-9-17+(1).pdf">Tech Information</a>
                  </div>
                </div>
              )}
            </div>
          </div>
        </div>
        <CtaBanner
          text="WANT TO GET MORE TIPS AND TRICKS?"
          buttonText="SUBSCRIBE TO THE NEWSLETTER"
          buttonUrl="/signup/"
        />
      </NewLayout>
    );
  }
  _onReady(event) {
    event.target.pauseVideo();
  }
}