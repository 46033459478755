import React, { Component } from 'react';
import Button from './button';

import styles from '../styles/scss/components/fullScreenBanner.module.scss';

export default class ctaBanner extends Component {

    render() {
        const { text, buttonText, buttonUrl, onClick } = this.props;
        return (
            <div className={styles.downloadBanner} >
                <div className={styles.downloadBannerContent}>
                    <p className={styles.downloadBannerTitle}>{text}</p>
                    <Button
                        text={buttonText}
                        url={buttonUrl}
                        className={styles.downloadButton}
                        target="_blank"
                        newBlue
                        pdf
                        rel="noopener noreferrer"
                        onClick={onClick}
                    />
                </div>
            </div>
        );
    }

}
